import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./assets/lang";
import api from "./axios/api";

const app = createApp(App);
app.config.globalProperties.$api = api;
app.config.globalProperties.$router = router;
app.config.globalProperties.$store = store;
app.config.globalProperties.$i18n = i18n;

app.use(store).use(i18n).use(router).mount("#app");