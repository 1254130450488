export default {
  success: "成功",
  loading_success: "加载成功",
  warning: "警告",
  error: "错误",
  danger: "危险",
  query: "查询",
  add: "新增",
  edit: "修改",
  delete: "删除",
  detail: "详情",
  submit: "提交",
  confirm: "确定",
  cancel: "取消",
  close: "关闭",
  action: "操作",
  upload: "上传",
  clear: "清空",
  save: "保存",
  replace: "替换",
  preview: "预览",
  pre_page: "共 ",
  suf_page: " 页",
  created: "创建时间",
  back: "返回",
  bind: "绑定",
  refresh: "刷新",
  look_all: "查看全部",
  no_unread_msg: "无未读消息",
  send: "发送",
  temp: "临时",
  exception: {
    unknown: "未知错误",
    401: "登录过期，请重新登录",
    403: "无权限",
    404: "请求的资源不存在",
  },
  validate: {
    invalid: "表单验证未通过",
    not_empty: "不能为空",
    re_password: "两次密码不一致",
  },
  tip: {
    delete: "确认要删除吗？",
    save: "确认要保存吗？",
    clear: "确认要清空吗？",
    wx_unbind: "此微信已绑定其他账号，是否解绑原账号？",
  },
  websocket: {
    open: "websocket 连接成功",
    close: "websocket 断开连接",
    success: "已连接",
    error: "已断开",
    user: "用户",
  },
  login: {
    title: "登录",
    login: "登录",
    logout: "退出",
    register: "注册",
    name: "昵称",
    account: "帐号",
    password: "密码",
    email: "邮箱",
    re_password: "再输入一次密码",
    auto: "自动登录",
    reset: "忘记密码",
    registered_account: "注册账号",
    has_account: "已有账号",
  },
  license: {
    title: "验证",
    has_license: "请访问邮箱中收到的链接进行验证",
    licensing: "验证中",
    license_success: "验证成功",
    register: "注册",
    reset: "密码重置",
  },
  home: {
    title: "首页",
    register: "注册",
    reset: "密码重置",
    user_info: "用户资料",
    password: "密码",
    logout: "退出登录",
  },
  register: {
    title: "注册",
  },
  reset: {
    title: "忘记密码",
    back_login: "回到登录",
    reset: "重置密码",
  },
  system: {
    user: {
      title: "用户管理",
      user: "用户",
      name: "昵称",
      account: "帐号",
      email: "邮箱",
      avatar: "头像",
      role: "角色",
      password: "密码",
      re_password: "再输入一次密码",
      new_password: "新密码",
      old_password: "旧密码",
      openId: "微信openId",
      phone: "手机号码",
      license: "注册码",
      locked: "锁定状态",
      locking: "锁定",
      unlocked: "解锁",
    },
    org: {
      title: "组织机构管理",
      org: "组织机构",
      name: "名称",
      code: "标识串",
      priority: "排序",
    },
    perm: {
      set: "权限设置",
      title: "权限管理",
      perm: "权限",
      name: "名称",
      code: "标识串",
      priority: "排序",
      url: "路径",
      type: "类型",
    },
    role: {
      title: "角色管理",
      role: "角色",
      name: "名称",
      code: "标识串",
    },
    dict: {
      title: "字典管理",
      dict: "字典",
      name: "名称",
      code: "标识串",
      priority: "排序",
    },
    msg: {
      title0: "消息管理",
      title: "消息标题",
      send: "发送消息",
      content: "消息内容",
      sendUser: "发送人",
      sendAddress: "发送地址",
      receiveUser: "接收人",
      receiveAddress: "接收地址",
      file: "附件",
      msgType: "消息类型",
      sendStatus: "发送状态",
      viewStatus: "查看状态",
      sendTime: "发送时间",
      filterType: "筛选类型",
    },
    file: {
      title: "文件管理",
      name: "文件名",
      tempName: "临时文件名",
      extension: "扩展名",
      path: "文件路径",
      size: "文件大小",
      md5: "md5",
      sha512: "sha512",
      replace: "文件替换",
    },
  },
  rez: {
    shop: {
      title: "门店管理",
      name: "名称",
      address: "地址",
      lng: "经度（wgs84）",
      lat: "纬度（wgs84）",
      phone: "手机号码",
      priority: "排序",
      extra: "备注",
      imgSet: "设置门店图片",
      roomSet: "设置门店房间",
      userSet: "设置门店员工",
      logoSet: "设置门店logo图片",
    },
    room: {
      title: "房间管理",
      name: "名称",
      price: "价格（元）",
      intro: "介绍",
      priority: "排序",
      imgSet: "设置房间图片",
      roomStatus: "房间状态",
    },
    device: {
      title: "设备管理",
      name: "名称",
      code: "编码",
      type: "设备类型",
    },
    member: {
      title: "会员管理",
      phone: "手机号码",
      discount: "折扣",
      money: "余额",
    },
    rechargeCard: {
      title: "充值卡管理",
      name: "名称",
      cost: "实付",
      denom: "面额",
      priority: "排序",
    },
    shopRoom: {
      shop: "门店",
      type: "房间类型",
      num: "数量",
      priority: "排序",
    },
    userShop: {
      user: "门店员工",
    },
    rez: {
      title: "预约管理",
      user: "预约人",
      shop: "预约门店",
      room: "预约房间",
      startTime: "预约开始时间",
      endTime: "预约结束时间",

      roomType: "房间类型",
      roomNum: "房间数量",
      phone: "预约手机号",
      time: "预约时间",
      payTime: "支付时间",
      tradeNo: "订单号",
      shouldPay: "应付（元）",
      realPay: "实付（元）",
      rezStatus: "预约状态",
      type: "预约单类型",
      paidCount: "当前订单消费次数",
    },
    dashboard: {
      title: "预约仪表盘",
    },
  },
};
