import { createStore } from "vuex";
export default createStore({
  state: {
    token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
    auth: <any>{
      user: {
        id: null,
        avatarId: null,
        name: null,
        account: null,
        password: null,
        email: null,
        phone: null,
      },
      perms: null,
      roles: null,
    },
    ws: {
      connectStatus: false,
    },
    wxWs: {
      connectStatus: false,
    },
    esucard: {
      ready2rollMs: 5000,
    },
  },
  getters: {
    getUser(state) {
      return state.auth.user;
    },
    getWS(state) {
      return state.ws;
    },
    getWSConnectStatus(state) {
      return state.ws.connectStatus;
    },
    getWxWS(state) {
      return state.wxWs;
    },
    getWxWSConnectStatus(state) {
      return state.wxWs.connectStatus;
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token); //存储token
    },
    delToken(state) {
      state.token = "";
      localStorage.removeItem("token"); //删除token
    },
    setAuth(state, auth) {
      state.auth = auth;
    },
    delAuth(state) {
      state.auth.user = {
        id: null,
        avatarId: null,
        name: null,
        account: null,
        password: null,
        email: null,
        phone: null,
      };
      state.auth.perms = null;
      state.auth.roles = null;
    },
    setWS(state, ws) {
      state.ws = ws;
    },
    delWS(state) {
      state.ws.connectStatus = true;
    },
    setWSConnectStatus(state, connectStatus) {
      state.ws.connectStatus = connectStatus;
    },
    setWxWS(state, ws) {
      state.wxWs = ws;
    },
    delWxWS(state) {
      state.wxWs.connectStatus = true;
    },
    setWxWSConnectStatus(state, connectStatus) {
      state.wxWs.connectStatus = connectStatus;
    },
  },
  actions: {},
  modules: {},
});
