export default {
  success: "success",
  loading_success: "loading success",
  error: "error",
  danger: "danger",
  query: "query",
  add: "add",
  edit: "edit",
  delete: "delete",
  detail: "detail",
  submit: "submit",
  confirm: "confirm",
  cancel: "cancel",
  close: "close",
  action: "action",
  pre_page: "",
  suf_page: " page in total",
  exception: {
    unknown: "unknown exception",
    401: "Login expired, please login again",
    403: "No permission",
    404: "The requested resource does not exist",
  },
  validate: {
    invalid: "invalid form",
    not_empty: "not empty",
  },
  tip: {
    delete: "Are you sure you want to delete?",
  },
  websocket: {
    open: "websocket open",
    close: "websocket close",
  },
  login: {
    title: "login",
    login: "login",
    logout: "logout",
    register: "register",
    account: "account",
    password: "password",
    auto: "remember me",
    reset: "reset",
    registered_account: "registered account",
  },
  home: {
    title: "home",
  },
  register: {
    title: "register",
  },
};
