import { createI18n } from "vue-i18n";
import zh_CN from "./zh-CN";
import en from "./en";

const messages = {
  "zh-CN": zh_CN,
  en: en,
};

const i18n = createI18n({
  legacy: !true,
  locale: localStorage.getItem("lang") || "zh-CN",
  fallbackLocale: "zh-CN",
  messages,
});

export default i18n;