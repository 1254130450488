import { createDiscreteApi, MessageProviderProps } from "naive-ui";
const messageProviderProps: MessageProviderProps = {
  placement: "top-right",
  closable: true,
};
const { message } = createDiscreteApi(["message"], {
  messageProviderProps: messageProviderProps,
});
const $message = (msg: string | null, type?: string) => {
  if (!msg) {
    msg = "";
  }
  if (!type || "info" == type) {
    message.info(msg);
  } else if ("error" == type) {
    message.error(msg);
  } else if ("warning" == type) {
    message.warning(msg);
  } else if ("success" == type) {
    message.success(msg);
  } else if ("loading" == type) {
    message.loading(msg);
  } else {
    message.info(msg);
  }
};
const info = (msg: string) => {
  return $message(msg, "info");
};
const error = (msg: string) => {
  return $message(msg, "error");
};
const warning = (msg: string) => {
  return $message(msg, "warning");
};
const success = (msg: string | null) => {
  return $message(msg, "success");
};
const loading = (msg: string | null) => {
  return $message(msg, "loading");
};

export default {
  $message,
  info,
  error,
  warning,
  success,
  loading,
};
