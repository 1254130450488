import { getCurrentInstance, ComponentInternalInstance, isRef } from "vue";
import i18n from "@/assets/lang/index";

import router from "../router";
import { useRoute } from "vue-router";
import store from "../store";
import api from "../axios/api";

export default function getBase() {
  const t = i18n.global.t;
  const locale = i18n.global.locale;

  const $api = api;
  const $router = router;
  const $store = store;
  const initParam = function (
    param: any,
    pagination?: any,
    judgeEmpty?: boolean
  ) {
    if (undefined == judgeEmpty || null == judgeEmpty) {
      judgeEmpty = true;
    }
    let result;
    if (isRef(param)) {
      result = param.value;
    } else {
      result = param;
    }
    if (undefined != pagination && null != pagination) {
      result.page = pagination.value.page - 1;
      result.size = pagination.value.pageSize;
    }
    if (judgeEmpty) {
      for (const key in result) {
        if (
          undefined === result[key] ||
          null === result[key] ||
          "" === result[key]
        ) {
          delete result[key];
        }
      }
    }
    return result;
  };

  const changeParam = function (param: any, data: any) {
    for (const key in param) {
      param[key] = data[key];
    }
    return param;
  };

  const getEnumOptions = function (data: any, disabledFields?: any) {
    const enums = [];
    for (let i = 0; i < data.length; i++) {
      enums.push({
        label: data[i].desc,
        value: data[i].field,
        disabled: !disabledFields
          ? false
          : disabledFields.indexOf(data[i].field) != -1,
      });
    }
    return enums;
  };

  const reInitAuth = function name() {
    $api.common.clearAuth().then((res: any) => {
      $store.commit("setAuth", res);
    });
  };

  const getUrl = function (
    sourceName: string,
    targetName: string,
    params?: any
  ) {
    let url = window.location.href;
    url = url.replace(getPathByName(sourceName), getPathByName(targetName));
    if (params) {
      for (const key in params) {
        url = url.replace(":" + key, params[key]);
      }
    }
    return url;
  };

  const getPathByName = function (name: string) {
    let routes = <any>$router.getRoutes();
    for (const key in routes) {
      const route = routes[key];
      if (route.name == name) {
        return route.path;
      }
    }
    return "";
  };

  return {
    $api,
    $router,
    $store,
    t,
    locale,
    initParam,
    changeParam,
    getEnumOptions,
    useRoute,
    reInitAuth,
    getUrl,
  };
}
