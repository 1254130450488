import axios from 'axios';
import router from '@/router';
import store from '@/store';
import $msg from '@/utils/message';
import i18n from '@/assets/lang/index';
const t = i18n.global.t;

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
	router.replace({
		name: 'login'
	});
};

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status: number, other: string) => {
	// 状态码判断
	switch (status) {
		// -1: 未登录状态，跳转登录页
		case -1:
			toLogin();
			break;
		// 403 token过期
		// 清除token并跳转登录页
		case 401:
			
			$msg.$message(t('exception.401'), 'error');
			localStorage.removeItem('token');
			store.commit('token', null);
			store.commit('delAuth');
			setTimeout(() => {
				toLogin();
			}, 1000);
			break;
		case 403:
			$msg.$message(t('exception.403'), 'error');
			break;
		// 404请求不存在
		case 404:
			$msg.$message(t('exception.404'), 'error');
			break;
		default:
			if (!other) {
				other = t('exception.unknown');
			}
			$msg.$message(other, 'error');
	}
};

/* 实例化请求配置 */
const instance = axios.create({
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	},
	// 请求时长
	timeout: 1000 * 30,
	withCredentials: true
});

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
	config => {
		const token = store.state.token;
		localStorage.setItem('token', '' + token);
		token && (config.headers.Authorization = token);
		return config;
	}
);

// 响应拦截器
instance.interceptors.response.use(
	// 请求成功
	res => {
		if (res.status !== 200) {
			$msg.error(res.data.message);
			return Promise.reject(res);
		}
		if (res.data.success) {
			return Promise.resolve(res.data.data);
		} else {
			errorHandle(res.data.code,res.data.message);
			return Promise.reject(res);
		}
	},
	// 请求失败
	error => {
		const { response } = error;
		if (response) {
			// 请求已发出，但是不在2xx的范围
			errorHandle(response.status, response.data);
			return Promise.reject(response);
		} else {
			// 处理断网的情况
			// eg:请求超时或断网时，更新state的network状态
			// network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
			// 后续增加断网情况下做的一些操作
			store.commit('networkState', false);
			// ...
		}
	}
);

export default instance;