/**
 * websocket
 */
import getBase from "@/utils/base";
import $msg from "@/utils/message";

const { $store, t, $api } = getBase();

let wsUrl: string = $api.commonUrl.websocket();
let websocket: any; // 用于存储实例化后websocket
let isConnect = false; // 连接标识 避免重复连接
let rec: any; // 断线重连后，延迟5秒重新创建WebSocket连接  rec用来存储延迟请求的代码

// 创建websocket
function creatWebSocket(
  getAlarmData: (data: any) => void,
  wsUrlParam?: string
) {
  console.log("websocket==================");
  if ("WebSocket" in window) {
    console.log("当前浏览器 windows");
  } else if ("MozWebSocket" in window) {
    console.log("当前浏览器 windows");
  } else {
    console.log("当前浏览器 Not support websocket");
  }

  try {
    initWebSocket(getAlarmData, wsUrlParam); // 初始化websocket连接
  } catch (e) {
    console.log("尝试创建连接失败");
    reConnect(getAlarmData, wsUrlParam); // 如果无法连接上 webSocket 那么重新连接！可能会因为服务器重新部署，或者短暂断网等导致无法创建连接
  }
}
// 初始化websocket
function initWebSocket(getAlarmData: (data: any) => void, wsUrlParam?: string) {
  websocket = new WebSocket(wsUrlParam ? wsUrlParam : wsUrl);
  console.log("websocket:", websocket);

  websocket.onopen = function (e: any) {
    websocketOpen(e);
  };
  // 接收
  websocket.onmessage = function (e: any) {
    // 回传给页面
    // 自定义全局监听事件
    window.dispatchEvent(
      new CustomEvent("onmessageWS", {
        detail: {
          data: e.data,
        },
      })
    );

    getAlarmData(e);
  };

  // 连接发生错误
  websocket.onerror = function () {
    console.log("WebSocket连接发生错误");
    isConnect = false; // 连接断开修改标识
    $store.commit("setWxWSConnectStatus", isConnect);
    reConnect(getAlarmData, wsUrlParam); // 连接错误 需要重连
  };

  websocket.onclose = function (e: any) {
    websocketclose(e);
  };
}

// 定义重连函数
const reConnect = (getAlarmData: (data: any) => void, wsUrlParam?: string) => {
  if (isConnect) return; // 如果已经连上就不在重连了
  rec && clearTimeout(rec);
  rec = setTimeout(function () {
    // 延迟5秒重连  避免过多次过频繁请求重连
    creatWebSocket(getAlarmData, wsUrlParam);
  }, 5000);
};

// 创建连接
function websocketOpen(e: any) {
  isConnect = true;
  $store.commit("setWxWSConnectStatus", isConnect);
  // $msg.success(t("websocket.open"));
}
// 关闭
function websocketclose(e: any) {
  console.log(e);
  isConnect = false; // 断开后修改标识

  $store.commit("setWxWSConnectStatus", isConnect);
  console.log("connection closed (" + e.code + ")");
  // $msg.error(t("websocket.close"));
}

// 数据发送
function websocketsend(data: any) {
  console.log("发送的数据", data, JSON.stringify(data));
  websocket.send(JSON.stringify(data));
}

// 实际调用的方法==============

// 发送
function sendWebSocket(
  data: any,
  getAlarmData: (data: any) => void,
  wsUrlParam?: string
) {
  //发送数据
  if (websocket && websocket.readyState === websocket.OPEN) {
    // 开启状态
    websocketsend(data);
  } else {
    // 若 未开启 / 正在开启 状态 ，则等待1s后重新调用
    setTimeout(function () {
      creatWebSocket(getAlarmData, wsUrlParam);
    }, 1000);
  }
}

// 关闭
const closeWebSocket = () => {
  if (null != websocket) {
    websocket.close();
    $store.commit("setWxWSConnectStatus", false);
  }
};

//导出方法
export { creatWebSocket, sendWebSocket, closeWebSocket, reConnect };
