import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import i18n from "@/assets/lang/index";
const t = i18n.global.t;

let routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      title: t("home.title"),
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    meta: {
      title: t("login.title"),
    },
  },
  {
    path: "/wxLogin",
    name: "wxLogin",
    component: () => import("@/views/WxLoginView.vue"),
    meta: {
      title: t("login.title"),
    },
  },
  {
    path: "/reset",
    name: "reset",
    component: () => import("@/views/ResetView.vue"),
    meta: {
      title: t("reset.title"),
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterView.vue"),
    meta: {
      title: t("register.title"),
    },
  },
  {
    path: "/license",
    name: "license",
    component: () => import("@/views/LicenseView.vue"),
    meta: {
      title: t("license.title"),
    },
  },
];
const systemRoutes: Array<RouteRecordRaw> = [
  {
    path: "/user",
    name: "user:page",
    component: () => import("@/views/system/UserView.vue"),
    meta: {
      title: t("system.user.title"),
    },
  },
  {
    path: "/org",
    name: "org:page",
    component: () => import("@/views/system/OrgView.vue"),
    meta: {
      title: t("system.org.title"),
    },
  },
  {
    path: "/perm",
    name: "perm:page",
    component: () => import("@/views/system/PermView.vue"),
    meta: {
      title: t("system.perm.title"),
    },
  },
  {
    path: "/role",
    name: "role:page",
    component: () => import("@/views/system/RoleView.vue"),
    meta: {
      title: t("system.role.title"),
    },
  },
  {
    path: "/dict",
    name: "dict:page",
    component: () => import("@/views/system/DictView.vue"),
    meta: {
      title: t("system.dict.title"),
    },
  },
  {
    path: "/msg",
    name: "msg:page",
    component: () => import("@/views/system/MsgView.vue"),
    meta: {
      title: t("system.msg.title0"),
    },
  },
  {
    path: "/file",
    name: "file:page",
    component: () => import("@/views/system/FileView.vue"),
    meta: {
      title: t("system.file.title"),
    },
  },
];

const rezRoutes: Array<RouteRecordRaw> = [
  {
    path: "/shop",
    name: "shop:page",
    component: () => import("@/views/rez/ShopView.vue"),
    meta: {
      title: t("rez.shop.title"),
    },
  },
  {
    path: "/rez",
    name: "rez:page",
    component: () => import("@/views/rez/RezView.vue"),
    meta: {
      title: t("rez.rez.title"),
    },
  },
  {
    path: "/shop/:id/room",
    name: "room:page",
    component: () => import("@/views/rez/RoomView.vue"),
    meta: {
      title: t("rez.room.title"),
    },
  },
  {
    path: "/room/:id/device",
    name: "device:page",
    component: () => import("@/views/rez/DeviceView.vue"),
    meta: {
      title: t("rez.device.title"),
    },
  },
  {
    path: "/member",
    name: "member:page",
    component: () => import("@/views/rez/MemberView.vue"),
    meta: {
      title: t("rez.member.title"),
    },
  },
  {
    path: "/rechargeCard",
    name: "rechargeCard:page",
    component: () => import("@/views/rez/RechargeCardView.vue"),
    meta: {
      title: t("rez.rechargeCard.title"),
    },
  },
  {
    path: "/dashboard",
    name: "rez:dashboard",
    component: () => import("@/views/rez/DashboardView.vue"),
    meta: {
      title: t("rez.dashboard.title"),
    },
  },
];

const tempRoutes: Array<RouteRecordRaw> = [
  {
    path: "/temp",
    name: "temp:page",
    component: () => import("@/views/temp/TempView.vue"),
    meta: {
      title: t("temp"),
    },
  },
];
routes = routes.concat(systemRoutes, rezRoutes, tempRoutes);
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${to.meta.title}`;
  }
  next();
});
export default router;
