// base.ts
export default class Base {
	/* 公共模块 */
	// static common = process.env.NODE_ENV === 'development' ? 'http://localhost:8081/yodb' : 'http://58.218.111.242:380/yodb';
	// static common = process.env.NODE_ENV === 'development' ? 'http://192.168.224.131:8081/rez' : 'http://58.218.111.242:380/rez';
	static common = process.env.NODE_ENV === 'development' ? 'http://192.168.224.134:8081/rez' : 'https://www.bantaohu.top/rez';
	// static common =  'http://localhost:8081/yodb';
	// static common = process.env.NODE_ENV === 'development' ? '/yodb' : 'http://produceCommon.com(生产线地址)';
	/* 活动模块 */
	static active = process.env.NODE_ENV === 'development' ? 'https://testActive.com(活动测试线地址)' : 'https://produceActive.com(活动生产线地址)';
}