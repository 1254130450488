import base from "./base";
import request from "./request";

class Api {
  /* 公共模块URL */
  public static commonUrl = {
    websocket: () =>
      `${base.common}/ws/webSocket`
        .replace("https", "wss")
        .replace("http", "ws"),
    wxWebsocket: (urlParam = "") =>
      (`${base.common}/ws/wxWebSocket?scene=` + urlParam)
        .replace("https", "wss")
        .replace("http", "ws"),
    enum: (urlParam = "") => `${base.common}/api/enum/` + urlParam,
    dict: (urlParam = "") => `${base.common}/api/dict/children/` + urlParam,
    register: () => `${base.common}/api/register`,
    reset: () => `${base.common}/api/reset`,
    wxLoginQrcode: (urlParam = "") =>
      `${base.common}/api/anon/wxLoginQrcode/` + urlParam,
    wxBindQrcode: (urlParam = "") =>
      `${base.common}/api/anon/wxBindQrcode/` + urlParam,
    permMenu: () => `${base.common}/api/perm/menu`,
    fileAdd: () => `${base.common}/api/file/add`,
    fileUpload: () => `${base.common}/api/file/upload`,
    fileReplace: () => `${base.common}/api/file/replace`,
    filePreview: (urlParam = "") =>
      `${base.common}/api/file/preview/` + urlParam,
    license: () => `${base.common}/api/license`,
    userSetAvatar: () => `${base.common}/api/user/setAvatar`,
    userEdit: () => `${base.common}/api/user/edit`,
    userEditPassword: () => `${base.common}/api/user/editPassword`,
    wsGetWsStatus: () => `${base.common}/api/ws/getWsStatus`,
  };
  /* 公共模块 */
  public static common = {
    login: (params = {}) => request.post(`${base.common}/api/login`, params),
    wxLogin: (params = {}) =>
      request.post(`${base.common}/api/wxLogin`, params),
    wxBind: (params = {}) =>
      request.post(`${base.common}/api/user/wxBind`, params),
    logout: (params = {}) => request.post(`${base.common}/api/logout`, params),
    register: (params = {}) => request.post(Api.commonUrl.register(), params),
    reset: (params = {}) => request.post(Api.commonUrl.reset(), params),
    wxLoginQrcode: (urlParam = "") =>
      request.get(Api.commonUrl.wxLoginQrcode(urlParam)),
    auth: () => request.post(`${base.common}/api/auth`),
    clearAuth: () => request.post(`${base.common}/api/clearAuth`),
    lang: () => request.post(`${base.common}/api/lang`),
    enum: (urlParam = "") => request.post(Api.commonUrl.enum(urlParam)),
    dict: (urlParam = "") => request.post(Api.commonUrl.dict(urlParam)),
    permMenu: () => request.post(Api.commonUrl.permMenu()),
    fileAdd: (params = {}) => request.post(Api.commonUrl.fileAdd(), params),
    fileUpload: (params = {}) =>
      request.post(Api.commonUrl.fileUpload(), params),
    fileReplace: (params = {}) =>
      request.post(Api.commonUrl.fileReplace(), params),
    filePage: (params = {}) =>
      request.post(`${base.common}/api/file/page`, params),
    fileDetail: (id = "") =>
      request.post(`${base.common}/api/file/detail/` + id),
    fileEdit: (params = {}) =>
      request.post(`${base.common}/api/file/edit`, params),
    fileDelete: (params = {}) =>
      request.post(`${base.common}/api/file/delete`, params),
    filePreview: (urlParam = "") =>
      request.post(Api.commonUrl.filePreview(urlParam)),
    license: (params = {}) => request.get(Api.commonUrl.license(), params),
    userSetAvatar: (params = {}) =>
      request.post(Api.commonUrl.userSetAvatar(), params),
    userDetail: (id = "") =>
      request.post(`${base.common}/api/user/detail/` + id),
    userAdd: (params = {}) =>
      request.post(`${base.common}/api/user/add`, params),
    userEdit: (params = {}) => request.post(Api.commonUrl.userEdit(), params),
    userDelete: (params = {}) =>
      request.post(`${base.common}/api/user/delete`, params),
    userPage: (params = {}) =>
      request.post(`${base.common}/api/user/page`, params),
    userEditPassword: (params = {}) =>
      request.post(Api.commonUrl.userEditPassword(), params),
    wsGetWsStatus: (params = {}) =>
      request.post(Api.commonUrl.wsGetWsStatus(), params),
    orgList: (params = {}) =>
      request.post(`${base.common}/api/org/list`, params),
    orgDetail: (id = "") => request.post(`${base.common}/api/org/detail/` + id),
    orgAdd: (params = {}) => request.post(`${base.common}/api/org/add`, params),
    orgEdit: (params = {}) =>
      request.post(`${base.common}/api/org/edit`, params),
    orgDelete: (params = {}) =>
      request.post(`${base.common}/api/org/delete`, params),
    orgPage: (params = {}) =>
      request.post(`${base.common}/api/org/page`, params),
    permList: (params = {}) =>
      request.post(`${base.common}/api/perm/list`, params),
    permDetail: (id = "") =>
      request.post(`${base.common}/api/perm/detail/` + id),
    permAdd: (params = {}) =>
      request.post(`${base.common}/api/perm/add`, params),
    permEdit: (params = {}) =>
      request.post(`${base.common}/api/perm/edit`, params),
    permDelete: (params = {}) =>
      request.post(`${base.common}/api/perm/delete`, params),
    permPage: (params = {}) =>
      request.post(`${base.common}/api/perm/page`, params),
    roleList: (params = {}) =>
      request.post(`${base.common}/api/role/list`, params),
    roleDetail: (id = "") =>
      request.post(`${base.common}/api/role/detail/` + id),
    roleAdd: (params = {}) =>
      request.post(`${base.common}/api/role/add`, params),
    roleEdit: (params = {}) =>
      request.post(`${base.common}/api/role/edit`, params),
    roleDelete: (params = {}) =>
      request.post(`${base.common}/api/role/delete`, params),
    rolePage: (params = {}) =>
      request.post(`${base.common}/api/role/page`, params),
    rolePerms: (id = "") => request.post(`${base.common}/api/role/perms/` + id),
    roleAddPerms: (params = {}) =>
      request.post(`${base.common}/api/role/addPerms`, params),
    dictList: (params = {}) =>
      request.post(`${base.common}/api/dict/list`, params),
    dictDetail: (id = "") =>
      request.post(`${base.common}/api/dict/detail/` + id),
    dictAdd: (params = {}) =>
      request.post(`${base.common}/api/dict/add`, params),
    dictEdit: (params = {}) =>
      request.post(`${base.common}/api/dict/edit`, params),
    dictDelete: (params = {}) =>
      request.post(`${base.common}/api/dict/delete`, params),
    msgPage: (params = {}) =>
      request.post(`${base.common}/api/msg/page`, params),
    msgDetail: (id = "") => request.post(`${base.common}/api/msg/detail/` + id),
    // msgAdd: (params = {}) => request.post(`${base.common}/api/msg/add`, params),
    msgEdit: (params = {}) =>
      request.post(`${base.common}/api/msg/edit`, params),
    msgDelete: (params = {}) =>
      request.post(`${base.common}/api/msg/delete`, params),
    msgRead: (params = {}) =>
      request.post(`${base.common}/api/msg/read`, params),
    msgUnreadPage: (params = {}) =>
      request.post(`${base.common}/api/msg/unreadPage`, params),
    msgSend: (params = {}) =>
      request.post(`${base.common}/api/msg/send`, params),
  };

  /* 预约模块URL */
  public static rezUrl = {
    shopImgSet: () => `${base.common}/api/shop/imgSet`,
    roomImgSet: () => `${base.common}/api/room/imgSet`,
    shopLogoSet: () => `${base.common}/api/shop/logoSet`,
  };

  /* 预约模块 */
  public static rez = {
    shopList: (params = {}) =>
      request.post(`${base.common}/api/shop/list`, params),
    shopDetail: (id = "") =>
      request.post(`${base.common}/api/shop/detail/` + id),
    shopAdd: (params = {}) =>
      request.post(`${base.common}/api/shop/add`, params),
    shopEdit: (params = {}) =>
      request.post(`${base.common}/api/shop/edit`, params),
    shopDelete: (params = {}) =>
      request.post(`${base.common}/api/shop/delete`, params),
    shopPage: (params = {}) =>
      request.post(`${base.common}/api/shop/page`, params),
    shopImgSet: (params = {}) => request.post(Api.rezUrl.shopImgSet(), params),
    shopImgDelete: (params = {}) =>
      request.post(`${base.common}/api/shop/imgDelete`, params),
    shopImgList: (id = "") =>
      request.post(`${base.common}/api/shop/imgList/` + id),
    shopLogoSet: (params = {}) =>
      request.post(Api.rezUrl.shopLogoSet(), params),
    shopLogoDelete: (params = {}) =>
      request.post(`${base.common}/api/shop/logoDelete`, params),
    shopLogoList: (id = "") =>
      request.post(`${base.common}/api/shop/logoList/` + id),
    shopRoomSet: (params = {}) =>
      request.post(`${base.common}/api/shop/roomSet`, params),
    shopRoomList: (id = "") =>
      request.post(`${base.common}/api/shop/roomList/` + id),
    shopUserSet: (params = {}) =>
      request.post(`${base.common}/api/shop/userSet`, params),
    shopUserList: (id = "") =>
      request.post(`${base.common}/api/shop/userList/` + id),

    eightHourOpen: (id = "") =>
      request.post(`${base.common}/api/shop/eightHourOpen/` + id),
    eightHourClose: (id = "") =>
      request.post(`${base.common}/api/shop/eightHourClose/` + id),

    rezDetail: (id = "") => request.post(`${base.common}/api/rez/detail/` + id),
    rezAdd: (params = {}) => request.post(`${base.common}/api/rez/add`, params),
    rezEdit: (params = {}) =>
      request.post(`${base.common}/api/rez/edit`, params),
    rezDelete: (params = {}) =>
      request.post(`${base.common}/api/rez/delete`, params),
    rezPage: (params = {}) =>
      request.post(`${base.common}/api/rez/page`, params),
    rezDashboard: (params = {}) =>
      request.post(`${base.common}/api/rez/dashboard`, params),
    roomList: (params = {}) =>
      request.post(`${base.common}/api/room/list`, params),
    roomDetail: (id = "") =>
      request.post(`${base.common}/api/room/detail/` + id),
    roomAdd: (params = {}) =>
      request.post(`${base.common}/api/room/add`, params),
    roomEdit: (params = {}) =>
      request.post(`${base.common}/api/room/edit`, params),
    roomDelete: (params = {}) =>
      request.post(`${base.common}/api/room/delete`, params),
    roomPage: (params = {}) =>
      request.post(`${base.common}/api/room/page`, params),
    roomImgSet: (params = {}) => request.post(Api.rezUrl.roomImgSet(), params),
    roomImgDelete: (params = {}) =>
      request.post(`${base.common}/api/room/imgDelete`, params),
    roomImgList: (id = "") =>
      request.post(`${base.common}/api/room/imgList/` + id),
    roomOpen: (id = "") =>
      request.post(`${base.common}/api/room/roomOpen/` + id),
    roomClose: (id = "") =>
      request.post(`${base.common}/api/room/roomClose/` + id),
    roomFinish: (id = "") =>
      request.post(`${base.common}/api/room/roomFinish/` + id),
    deviceList: (params = {}) =>
      request.post(`${base.common}/api/device/list`, params),
    deviceDetail: (id = "") =>
      request.post(`${base.common}/api/device/detail/` + id),
    deviceAdd: (params = {}) =>
      request.post(`${base.common}/api/device/add`, params),
    deviceEdit: (params = {}) =>
      request.post(`${base.common}/api/device/edit`, params),
    deviceDelete: (params = {}) =>
      request.post(`${base.common}/api/device/delete`, params),
    devicePage: (params = {}) =>
      request.post(`${base.common}/api/device/page`, params),

    memberList: (params = {}) =>
      request.post(`${base.common}/api/member/list`, params),
    memberDetail: (id = "") =>
      request.post(`${base.common}/api/member/detail/` + id),
    memberAdd: (params = {}) =>
      request.post(`${base.common}/api/member/add`, params),
    memberEdit: (params = {}) =>
      request.post(`${base.common}/api/member/edit`, params),
    memberDelete: (params = {}) =>
      request.post(`${base.common}/api/member/delete`, params),
    memberPage: (params = {}) =>
      request.post(`${base.common}/api/member/page`, params),

    rechargeCardList: (params = {}) =>
      request.post(`${base.common}/api/rechargeCard/list`, params),
    rechargeCardDetail: (id = "") =>
      request.post(`${base.common}/api/rechargeCard/detail/` + id),
    rechargeCardAdd: (params = {}) =>
      request.post(`${base.common}/api/rechargeCard/add`, params),
    rechargeCardEdit: (params = {}) =>
      request.post(`${base.common}/api/rechargeCard/edit`, params),
    rechargeCardDelete: (params = {}) =>
      request.post(`${base.common}/api/rechargeCard/delete`, params),
    rechargeCardPage: (params = {}) =>
      request.post(`${base.common}/api/rechargeCard/page`, params),

    rechargePage: (params = {}) =>
      request.post(`${base.common}/api/recharge/page`, params),
  };

  public static tempUrl = {
    title2sql: () => `${base.common}/api/temp/title2sql`,
  };
  public static temp = {
    title2sql: (params = {}) => request.post(Api.tempUrl.title2sql(), params),
  };
}

export default Api;
